@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-color: #09090b;
  color: white;
}

h1 {
  font-size: x-large;
  color: yellowgreen;
}

h2 {
  font-size: medium;
  color: whitesmoke;
}

hr {
  border-color: rgb(69, 16, 16);
  border-style: dotted;
}
